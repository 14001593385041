@font-face {
  font-family: 'CustomFont', monospace;
  src: url('/webfontkit-20221003-151818/thailand-webfont.woff') format('woff'),
       url('/webfontkit-20221003-151818/thailand-webfont.woff2') format('woff2'),
}


.App {
  /* background-color: #50C878; */
  /* background: linear-gradient(.25turn, #a9e6a9, #168b3d);  
  background-color: #a9e6a9; */
  /* background: linear-gradient(
    to right,
    #168b3d 45%,
    #a9e6a9 25% 75%,
    #168b3d 60%
  ); */
  /* background: linear-gradient(to right, #0b6128 -15%, #70dd70 50%, #0b6128 115%); */
 
 
  /* background: linear-gradient(to right, #1c5d9b -5%, #69afdf 50%, #1c5d9b 105%); */

  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
.admin-top{
 
}

.add-items{

}


.admin-left-side{
 width: 50%;
float: left;
}

.items-and-reviews-scroll-box{
  padding: 30px;
  float: right;
  margin-right: 1vw;
  width: 48%;
  margin-top: 2vh;
  height: 95vh;
  overflow-x:hidden;
  overflow-y:scroll;
  border-radius: 8px;
  border: 2px solid #06771f;
}


.admin-site{
 
}

.reviews-manage{
  display: inline;
}

.items-manage{
  display: inline;
}
.App-link {
  color: #61dafb;
}

.sign-in-div{
  padding: 20px;
}

.sign-in-btn{
  width: 250px;
  height: 100px;
  border-radius: 8px;
  font-family: 'CustomFont', monospace;
  font-weight: bold;
  font-size: 30px;
}

.button1-admin-spacing{
display: inline;
  padding-right: 20px;
}
.color-nav{
  /* height: 10vh; */
  padding: 1.5vh;
  font-size: 20px;
  /* font-family: 'CustomFont', monospace; */
  /* font-weight: bold; */
  width: 100vw;

  /* background-color: #16832ed5; */

  display: inline-block;

}

.bottom{
  text-align: center;
}




.navbar-title{
    font-size: 20cdpx;
    font-weight: bold;

}



.me-auto{
  
  margin-left: 4vw;

}

.nav-link{
  color: #5a5a5a;
  margin-left: 6vw;
  font-size: 20px;
}

.carouselpics{
  position: relative;
  pointer-events: none;
  user-select: none;
  width: 79.5vw; 
  /* width: 80vw; */
  margin-left: auto;
  margin-right: auto;
}

.carouseltext{
 width: 100%;
 text-align: center;

  font-size: 2.5vw;
color: white;

}

.carouseltext2{
  font-size: 1.3vw;
color: white

}

.carous-caption{
 padding-top: 50px;
}
.carous{
  /* margin-top: 5vh; */
  
  /* margin-top: 1vh;  */
  position: relative;
  text-align: center;

}


.map{
  position: relative;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}






.openinghours {
  position: relative;
  font-family:Lucida Console;
  border-radius:4px;
  margin:10px;
  box-shadow: 0 0 10px black;
  padding:0 10px 0 10px;
  overflow: hidden;
  display: inline-block;
  margin-left: 500px;
  margin-bottom: 150px
}

.openinghourscontent {
  float:left;
}
.openinghourscontent h2 {
  display:block;
  text-align:center;
  margin-top:.33em;
}
.openinghourscontent button {
  color:white;
  font-family:Courier New;
  font-size:large;
  font-weight:bolder;
  background-color:#4679BD;
  border-radius:4px;
  width:100%;
  margin-bottom:10px;
}
.today {
  color: #8AC007;
}
.opening-hours-table tr td:first-child {
  font-weight:bold;
}
#open-status {
  display:block;
  margin-top:-1em;
  text-align:center;
  border:dotted lightgrey 3px;
}
.openorclosed:after {
  content:" open during these hours:";
}
.open {
  color:green;
}

.open:after {
  /* content:" Open"; */
  color: #6C0;
}
.closed:after {
  /* content:" Closed"; */
  color: red;
}
.opening-hours-table tr td {
  padding:5px;
}

.faqs {
  width: 60%;
  
  margin: 0 auto;
  padding: 15px;
  
}

.faq-q-test-1{
}

.faqs .faq {
  
  margin: 15px;
  padding: 15px;
  background: rgb(249, 249, 249);
  border-radius: 10px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}






.header{
  color:#006627;

  text-align: center;
font-family: 'CustomFont', monospace;
  font-size:50px;
  font-weight: bold;
  padding-top: 40px;
  padding-bottom: 10px;
}

.header-2{
  text-align: center;
  font-family: 'CustomFont', monospace;
    font-size:30px;
    font-weight: bold;
  
    padding-bottom: 10px;
}


.header-3{
  color:#006627;

  margin-top: 10vh;
  margin-bottom: 5vh;
  text-align: center;
  font-family: 'CustomFont', monospace;
  font-weight: bold;
  font-size: 20px;
}

.admin-site{
  padding-left: 20px;
}

.admin-header{
  padding-top: 15px;
  

  font-family: 'CustomFont', monospace;
  font-weight: bold;
  font-size:40px;


}

.hr-1-1{
  width: 40vw;
  border: 3px solid #06771f;
  
  /* border: 3px solid rgb(0, 0, 0);; */
}

.hr-1{
  width: 45vw;
  border: 3px solid #06771f;
  
  /* border: 3px solid rgb(0, 0, 0);; */
}


.hr-2{
  width: 500px;
 
  border: 1px solid rgb(0, 0, 0);;
}

.item-info{

  font-family: 'CustomFont', monospace;
  font-size:18px;
  
}


.admin-button{
  padding-left: 20px;
  padding-right: 20px;
  padding-top:8px;
  padding-bottom:8px;

  border-radius: 8px;
  font-family: 'CustomFont', monospace;

  font-size: 18px;
}

.all-items{
  padding-bottom: 50px;
}



.all-reviews{
  padding-top: 20px;
  padding-bottom: 50px;
}
.each-item{
  padding-top: 30px;
  padding-left: 15px;
  
  
}

.upload-image-text{
  font-family: 'CustomFont', monospace;
  font-size:18px;
  font-weight: bold;
  display: inline;
  padding-right: 40px;
}

.add-item-text{
  font-family: 'CustomFont', monospace;
  font-size:30px;
  
  font-weight: bold;
  padding-bottom: 10px;
}

.add-review-text{
  font-family: 'CustomFont', monospace;
  font-size:30px;
  
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 10px;
}

.admin-header2{
  padding-top: 20px;

  font-family: 'CustomFont', monospace;
  /* font-weight: bold; */
  font-size:18px;
  
}


.about{
  text-align: center;


}


.about-text{
  padding-top: 30px;
  font-family: 'CustomFont', monospace;
  font-size:18px;
  text-align: center;
  width: 60vw;
  margin-left: 20vw;
  justify-content: center;
  align-items: center;
}

.about-div{

}

.contact_message{
  width: 50%;

}






.contact_text{
  text-align: center;
}

/* .html {
  scroll-behavior: smooth;
} */


body{
  max-width: 100%;
 overflow-x: hidden;
}

.contact-form{
  position: relative;
  text-align: center;
   border-radius: 30px;
  /* border: 2px solid #06771f; */
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2);

}


.feedback{

  width: 200px;
}

.contact-body{
  width: 60%;
  background-color: rgb(255, 255, 255, 1);
  border-radius: 30px;

  border: 2px solid #06771f;
  /* box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2); */

  box-shadow: rgb(0 0 0 / 50%) 10px 10px 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0px;
  z-index: 10;
  margin: 0 auto;
  margin-top: 150px;
  margin-bottom: 50px;

  /* color: white; */
}

.hero{
  display: inline-block;

  padding: 2rem 1rem;
  display:flex;
  flex-direction: row;
    flex-wrap: wrap;
  width: 100%;

  
}

.hero-about{
  
  float: left;
  display: inline-block;
  
  padding: 1rem 2rem;
  
  width: 100vw;
}
.hero-map{

  text-align:center;
  display: inline-block;
  padding: 1rem 2rem;
  vertical-align: middle;
  width: 80vw;
  margin-left: 10vw;
  margin-right: 10vw;
height: 70vh;

  
}


.map-div{
  padding-bottom: 50px;
}




/* border: 1px solid rgb(0, 0, 255); */
.page-header{
  text-align: center;
  margin-bottom: 50px;
}

.row{
  justify-content: center;
}

.col-sm-3{
  background: rgb(244, 244, 244);
    border-radius: 30px;
  /* box-shadow: rgb(0 0 0 / 50%) 10px 10px 18px; */
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  box-sizing: border-box;
  z-index: 10;
  margin: 0 auto;
  margin-top: 50px;  
  margin-left: 1vw;
  margin-right: 1vw;
  /* border: 2px solid #06771f; */
  padding: 20px; 
  width: 22vw;
  height: 300px;
  min-width: 400px;
}




.page-header{
  text-align: center;
  margin-bottom: 50px;
}



.products-all{
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 5vh;
}


.product-item{
  width: 22vw;
  /* background-color: rgba(255, 255, 255, 0.4); */
  
  background: rgb(244, 244, 244);
  
  /*  margin: 15px;
  padding: 15px;
  background: rgb(249, 249, 249);
  border-radius: 10px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1); */

  border-radius: 30px;
  /* border: 2px solid #06771f; */


  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 20px; 
  z-index: 10;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 100px;
  align-items: center;
  text-align: center;

  min-width: 300px;
  position: relative;
 
}

.header-product-price{
  text-align: center;
  
  font-family: 'CustomFont', monospace;
  font-weight: bold;
  font-size:32px;
  /* padding-bottom: 10px; */
  padding-top: 25px;
  padding-bottom: 10px;
  color:#006627;
 margin-top: auto;
}

/* .header-product-price-div{
  padding-top: 50px;
  width: 22vw;
  justify-content: center;
} */


.product-pics{
  pointer-events: none;
  user-select: none;
  width: 20vw;
 
  margin-left: auto;
  margin-right: auto;
}


.price-orig{
  width: 10vw;
  color:#ff0000;

  align-self: flex-end;
}


.price-sale{
    color:#006627;
  position: absolute;
  align-items: center;
  text-align: center;
}

#bottom {

  bottom: 0;

}

.review-name{
  font-weight: bold;
  padding-bottom: 10px;
}
.rating-stars{
  padding-bottom: 10px;
}

.panel-primary{
  align-items: center;
}


.site-footer
{

  background-color: #006627;
  padding:35px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#ffffff;
  
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#ffffff;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#ffffff;
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#ffffff;
    font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}